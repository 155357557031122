import Modal from './Modal.vue';
import { uuid } from '@/services/uuid';
import type { App, AsyncComponentLoader, Component, ComponentPublicInstance, ShallowRef } from 'vue';
import { createApp, defineAsyncComponent, defineComponent, getCurrentInstance, h, onMounted, ref, shallowRef } from 'vue';

import './polyfill';

export interface UseModal {
  createInstance: (binding: Component, attrs: ModalProps & Record<string, unknown>) => void;
  showModal: <T>(el?: AsyncComponentLoader<new () => T>, props?: Record<string, unknown>) => void;
  closeModal: () => void;
}

export interface ModalProps {
  header?: string;
  outside?: boolean;
  size?: 'sm' | 'md' | 'lg' | 'full';
}

type DynamicDialog = [Component, () => void, () => void];

const NO_SCROLL_CLASS = 'noscroll';

const modals = shallowRef<App<Element>[]>([]);
const dialogContainers = shallowRef<HTMLDialogElement[]>([]);
const dialogInstances = shallowRef<ComponentPublicInstance[]>([]);
const dialogUuids = shallowRef<string[]>([]);

function openDialog(): void {
  const dialogContainer = dialogContainers.value.pop();
  if (dialogContainer && document.body.contains(dialogContainer)) {
    dialogContainer.showModal();
    document.body.classList.add(NO_SCROLL_CLASS);
  } else {
    document.body.classList.remove(NO_SCROLL_CLASS);
    console.error('Dialog container is not in the document.');
  }
}

export function closeDialog(): void {
  document.body.classList.remove(NO_SCROLL_CLASS);
  const dialogContainer = dialogContainers.value.pop();
  
  if (dialogContainer) {
    dialogContainer.close();
    setTimeout(() => dialogContainer.remove(), 100);

    modals.value.pop();
    dialogInstances.value.pop();
    dialogUuids.value.pop();
  }
}

export function dialog<T>(el: AsyncComponentLoader<new () => T>): DynamicDialog {
  const component = defineAsyncComponent(el);

  const { showModal, closeModal, createInstance } = useModal();

  const elementUuid = ref<string>('');

  const wrapper = defineComponent({
    setup() {
      const instance = getCurrentInstance();
      const attrs = instance ? instance.attrs : {};

      onMounted(() => {
        elementUuid.value = uuid.value;
        createInstance(component, attrs);
      });

      return () => h('div', { id: elementUuid.value });
    },
  });

  return [wrapper, showModal, closeModal];
}

export function useModal(): UseModal {
  const { initModal, createInstance } = setupModal();

  function showModal<T>(el?: AsyncComponentLoader<new () => T>, props?: Record<string, unknown>): void {
    if (el) {
      const component = defineAsyncComponent(el);
      createInstance(component, props || {});
    }

    initModal();
    openDialog();
  }

  function closeModal(): void {
    closeDialog();
  }

  return {
    createInstance,
    showModal,
    closeModal,
  };
}

function appendDialog(bindAttrs: ShallowRef<(ModalProps & Record<string, unknown>) | null>): HTMLDialogElement {
  const id = uuid.value;

  const dialogContainer = document.createElement('dialog');
  dialogContainer.id = id;

  dialogContainer.classList.add('dialog');

  if(bindAttrs.value?.class) {
    if(bindAttrs.value.class.toString().split(' ').length > 1) {
      bindAttrs.value.class.toString().split(' ').forEach((className: string) => {
        dialogContainer.classList.add(className);
      });
    } else {
      dialogContainer.classList.add(bindAttrs.value.class.toString());
    }
  }

  if (bindAttrs.value?.size) {
    dialogContainer.classList.add(`dialog-${bindAttrs.value.size}`);
  }

  document.body.appendChild(dialogContainer);
  dialogContainers.value.push(dialogContainer);
  return dialogContainer;
}

function setupModal() {
  const bindContainer = shallowRef<Component | null>(null);
  const bindAttrs = shallowRef<(ModalProps & Record<string, unknown>) | null>(null);

  function initModal(): void {
    const dialogContainer = appendDialog(bindAttrs);

    if (bindAttrs.value?.outside !== false) {
      dialogContainer.addEventListener('click', closeDialog);
    }

    const modalProps: Record<string, unknown> = { ...bindAttrs.value };

    const modal = createApp({
      render: () => h(Modal, modalProps, { default: () => (bindContainer.value ? h(bindContainer.value, modalProps) : null) }),
    });

    modals.value.push(modal);
    dialogInstances.value.push(modal.mount(dialogContainer));
    dialogContainers.value.push(dialogContainer);
  }

  async function createInstance(binding: Component, attrs: ModalProps & Record<string, unknown>): Promise<void> {
    bindContainer.value = binding;
    bindAttrs.value = attrs;
  }

  return {
    initModal,
    createInstance,
  };
}
